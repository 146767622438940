import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today is Nightmare in NULU!  From 1-4pm kids can Trick or Treat
here at The Ville along with all the other NULU shops.  There will also
be a Kids Zone and Face Painting.  The Nightmare will continue until the
evening for all other activities.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`The Making Strides Against Breast Cancer walk is this morning
starting at 9:30am.  The walk will be traveling down Main Street so you
may want to try to arrive earlier than usual for class and park on a
side street.  There will be no parking on Main Street.`}</em></strong></p>
    <p>{`Bench Press 2-2-2-2-2\\@90% 1RM`}</p>
    <p>{`Single Arm DB Rows 4-4-4-4-4/arm`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`50ft Bear Crawl`}</p>
    <p>{`10-Shoulder Press (95/65)`}</p>
    <p>{`50ft Burpee Broad Jump`}</p>
    <p>{`10-L Pullups`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Tomorrow (Sunday) we’re having our annual Halloween WOD!  Come to
workout in your best costume, there will be prizes!  Free to all so you
can bring a friend as well.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`November 17th-18th CrossFit the Ville will be hosting a USAW
Weightlifting Level 1 Coaching Certification Course!  The Scope and
Sequence of this course includes the teaching progressions of the
Snatch, Clean & Jerk, and all associated movements.  Participants will
gain knowledge and experience of programming of training for both
competitive weightlifters and for athletes using the weightlifting
movements for strength and power development as it applies to their
chosen sport.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Lead Instructor: Mark Cannella, President and Head Coach of Columbus
Weightlifting in Columbus, Ohio.  He has been part of the lifting
community as an athlete, coach, administrator, and official for over 29
years.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`This 2 day course is \\$499.  Those who complete this course will be
awarded their USAW Level 1 Coaching Certificate.  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more information contact Daniel\\@crossfittheville.org or`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Mark Cannella at mark\\@columbusweightlifting.org.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Click the link below to register: `}</em></strong></p>
    <p><a parentName="p" {...{
        "href": "https://webpoint.usaweightlifting.org/wp15/Events2/Registrations/Register.wp?rgs_EventID=168977"
      }}>{`https://webpoint.usaweightlifting.org/wp15/Events2/Registrations/Register.wp?rgs_EventID=168977`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      